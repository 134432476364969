@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

@font-face {
  font-family: 'Ranua';
  font-weight: 300;
  src: url('/public/fonts/Rauna/300.otf');
}

@font-face {
  font-family: 'Ranua';
  font-weight: 400;
  src: url('/public/fonts/Rauna/400.otf');
}

@font-face {
  font-family: 'Ranua';
  font-weight: 500;
  src: url('/public/fonts/Rauna/500.otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: 'Ranua' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
